import React from "react";
import Tom from "./images/tom.png";
import {Image} from "react-bootstrap";
function Error () {
    return(
        <>
        <div style={{textAlign:"centre"}}>
      <h1>OOps! Page not Found </h1>
      <div>
          <Image src={Tom}/>
          </div>
          </div>
    </>
    );
}
export default Error;