import React, { Component } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_kelly from "@amcharts/amcharts4/themes/kelly";

am4core.useTheme(am4themes_animated);
am4core.useTheme(am4themes_kelly);
export default class Opexlinechart extends Component {
  componentDidMount() {
    var chart = am4core.create("chartdiv2", am4charts.XYChart);

    // Add data
    chart.data = [{
      "month": "Jan",
      "budget": -10,
      }, 
      {
      "month": "Mar",
      "budget": 20,
      }, 
      {
      "month": "May",
      "budget": 40,
      },
       {
      "month": "Jul",
      "budget": 0,
     },
      {
      "month": "Sept",
      "budget": -5,
     }, 
     {
      "month": "Nov",
      "budget": 10,
     }
    //  ,
    //   {
    //   "country": "UK",
    //   "budget": 99,
          
    // }, 
    // {
    //   "country": "Belgium",
    //   "budget": 60,

    // },
    //  {
    //   "country": "The Netherlands",
    //   "budget": 50,
    
    // }
  ];
    
    // Create axes
    var dateAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    dateAxis.dataFields.category = "month";
    //dateAxis.title.text = "Month Name";
    dateAxis.fontSize=12;
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 25;
    dateAxis.renderer.grid.template.disabled = true;
    var  valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    //valueAxis.title.text = " Total Budget (M)";
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.fontSize=12;
    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.stroke = am4core.color("#03e8fc");
    series.strokeWidth = 1;
    series.dataFields.valueY = "budget";
    series.dataFields.categoryX = "month";
    //series.name = "Budget";
    series.tooltipText = "{name}: [bold],{color}:[grey],{valueY.percent}%[/]";
   
    
    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    
    // Add legend
    chart.legend = new am4charts.Legend();

// let scrollbarX = new am4charts.XYChartScrollbar();
// scrollbarX.series.push(series);
// chart.scrollbarX = scrollbarX;
    
this.chart = chart;
  }
 

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div id="chartdiv2" style={{ width: "100%", height: "200px" ,backgroundColor:"white"}}></div>
    );
  }
}

