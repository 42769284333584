import React from 'react'
import { Boxplot, computeBoxplotStats } from 'react-boxplot'

const values = [
  // 14,
  // 15,
  // 16,
  // 16,
  // 17,
  // 17,
  // 17,
  // 17,
  // 17,
  // 18,
  // 18,
  // 18,
  // 18,
  // 18,
  // 18,
  // 19,
  // 19,
  // 19,
  // 20,
  // 20,
  // 20,
  // 20,
  // 20,
  // 20,
  // 21,
  // 21,
  // 22,
  // 23,
  // 24,
  // 24,
  // 29,
  22,
  25,
  20,
  19,
  27,
  64,
  23,
  17,
  20,
  18,
]

const Example = () => (
  <Boxplot  
    width={200}
    height={30}
    orientation="horizontal"
    min={20}
    max={33}
    color={"#3D9970"}
    stats={computeBoxplotStats(values)}
  />
)
export default Example;