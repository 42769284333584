import React, { Component } from "react";
import Profile from "./images/profile.png";
// Table from react-bootstrap
import { Table, Image} from "react-bootstrap";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";

export class Opexdatatable extends Component {
    render() {
        return (
            <>
                <Table responsive bordered hover >
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Account</th>
                            <th>Item</th>
                            <th>This Period</th>
                            <th>Budget</th>
                            <th>Variance</th>
                            <th>%</th>
                            <th>Updated By</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            data-toggle="collapse"
                            data-target=".multi-collapse1"
                            aria-controls="multiCollapseExample1"
                        >
                            <td>1</td>
                            <td>162001</td>
                            <td>Manning Expenses</td>
                            <td>65,442.24</td>
                            <td>69,656.00</td>
                            <td>4,213.76</td>
                            <td><span style={{color:"#03e8fc"}}>6.05%</span></td>
                            <td><Image src={Profile} roundedCircle style={{ width: "18px", height: "18px",marginRight:"4px" }}></Image>2019 feb 10</td>
                            <td>3 fewer crew members</td>
                        </tr>
                        <tr class="collapse multi-collapse1" id="multiCollapseExample1">
                            <td>1.1</td>
                            <td></td>
                            <td>Wages</td>
                            <td>53,186.02</td>
                            <td>54,056.00</td>
                            <td>869.98</td>
                            <td><span style={{color:"#03e8fc"}}>1.87%</span></td>
                            <td><Image src={Profile} roundedCircle style={{ width: "18px", height: "18px",marginRight:"4px" }}></Image>2019 feb 10</td>
                            <td>3 fewer crew members</td>
                        </tr>
                        <tr class="collapse multi-collapse1" id="multiCollapseExample1">
                            <td>1.2</td>
                            <td></td>
                            <td>Overtime</td>
                            <td>14,8389.14</td>
                            <td>13,35.00</td>
                            <td>-148.89</td>
                            <td><span style={{color:"#F3AD1C"}}>11.32%</span></td>
                            <td><Image src={Profile} roundedCircle style={{ width: "18px", height: "18px",marginRight:"4px" }}></Image>2019 feb 10</td>
                            <td>More OT fewer crew n..</td>
                        </tr>
                        <tr class="collapse multi-collapse1" id="multiCollapseExample1">
                            <td>1.3</td>
                            <td></td>
                            <td>Medical Expenses</td>
                            <td>1,448.30</td>
                            <td>3,708.00</td>
                            <td>2,259.70</td>
                            <td><span style={{color:"#03e8fc"}}>60.94%</span></td>
                            <td><Image src={Profile} roundedCircle style={{ width: "18px", height: "18px",marginRight:"4px" }}></Image>2019 feb 10</td>
                            <td>Corona Virus less travel</td>
                        </tr>
                        <tr
                            data-toggle="collapse"
                            data-target=".multi-collapse2"
                            aria-controls="multiCollapseExample2"
                        >
                           <td>2</td>
                            <td>16,3501</td>
                            <td>Deck Stores</td>
                            <td>1,633.86</td>
                            <td>4,663.00</td>
                            <td>3,029.14</td>
                            <td><span style={{color:"#03e8fc"}}>64.97%</span></td>
                            <td><Image src={Profile} roundedCircle style={{ width: "18px", height: "18px",marginRight:"4px" }}></Image>2019 feb 10</td>
                            <td></td>
                        </tr>
                        <tr class="collapse multi-collapse2" id="multiCollapseExample2">
                            <td>Child col 1</td>
                            <td>Child col 2</td>
                            <td>Child col 3</td>
                        </tr>
                        <tr
                            data-toggle="collapse"
                            data-target=".multi-collapse2"
                            aria-controls="multiCollapseExample2"
                        >
                           <td>3</td>
                            <td>163502</td>
                            <td>Engine Stores</td>
                            <td>1,322.66</td>
                            <td>1,749.00</td>
                            <td>426.34</td>
                            <td><span style={{color:"#03e8fc"}}>24.32%</span></td>
                            <td><Image src={Profile} roundedCircle style={{ width: "18px", height: "18px",marginRight:"4px" }}></Image>2019 feb 10</td>
                            <td></td>
                        </tr>
                        <tr class="collapse multi-collapse2" id="multiCollapseExample2">
                            <td>Child col 1</td>
                            <td>Child col 2</td>
                            <td>Child col 3</td>
                        </tr>
                        <tr
                            data-toggle="collapse"
                            data-target=".multi-collapse2"
                            aria-controls="multiCollapseExample2"
                        >
                           <td>4</td>
                            <td>163900</td>
                            <td>Lubricating Oils</td>
                            <td>7,486.97</td>
                            <td>12,224.00</td>
                            <td>4,737.03</td>
                            <td><span style={{color:"#03e8fc"}}>39.67%</span></td>
                            <td><Image src={Profile} roundedCircle style={{ width: "18px", height: "18px",marginRight:"4px" }}></Image>2019 feb 10</td>
                            <td></td>
                        </tr>
                        <tr class="collapse multi-collapse2" id="multiCollapseExample2">
                            <td>Child col 1</td>
                            <td>Child col 2</td>
                            <td>Child col 3</td>
                        </tr>
                    </tbody>
                </Table>
            </>
        );
    }
}

export default Opexdatatable;
