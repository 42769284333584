import React from "react";
import Opexlinechart from "./Opexlinechart";
import Financeboxchart from "./Financeboxchart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBalanceScale,faMoneyBill,faChartPie } from '@fortawesome/free-solid-svg-icons';
export default class Financesection extends React.Component {
       render() {
        return (
        <>
        
        <div className="rig_side">
        <section class="rig_side p-3 shadow" style={{backgroundColor:"white",marginLeft: "0",textAlign: "left"}}>
        <div style={{ marginLeft: 0, marginRight: 0 }} className="col pt-4">
            <div className=" card_custom ">
                <p style={{fontSize:"10px",color:"grey"}}>Oldendorff Carriers</p>
                <p style={{fontSize: "15px",color: "black"}}><b>Birte Oldendorff</b></p>
            <div className=" mt-0">
                    <p style={{fontSize: "12px",color: "black",textDecoration: "none",textAlign: "left"}}>
                   <FontAwesomeIcon icon={faChartPie}className="mr-1"></FontAwesomeIcon>
                   <b>Financial Snapshpot</b></p>
                   <p style={{fontSize:"10px",color:"grey"}}>Last Updated 12 jan2020,11:24(GMT)</p>
            </div>
             </div>
             </div>
            <div className="col mt-2">
                <div className="card card_custom ">
                    <div className="card-body">
                        <p style={{fontSize: "8px",color: "grey"}}>Total Expenditure<span style={{float: "right"}}>See More </span></p>
                        <p style={{fontSize: "13px",color: "black"}}>
                        <b>Total Budget:$103,000.50</b>
                        </p>
                <div className="plot">
                <Financeboxchart></Financeboxchart>
                </div>
                 </div>
                
               </div>
            </div>
            <div className="col mt-3">
                <hr></hr>
                <div className=" card_custom">
                    <div>
                    <p style={{fontSize: "13px",color: "black",textDecoration: "none",textAlign: "left"}}>
                    <FontAwesomeIcon icon={faBalanceScale} className="mr-1"></FontAwesomeIcon>
                    <b>OPEX Variance</b>
                    </p>
                    </div>
                </div>
            </div>
            <div className="col mt-2">
                <div className="card card_custom">
                    <div className="card-body">
                        <p style={{fontSize: "8px",color: "grey"}}><b>OPEX Variance</b><span style={{float: "right"}}>See More </span></p>
                        <p style={{fontSize: "13px",color: "black",marginBottom: "0"}}>
                        <b>Total Budget:$103,000.50</b>
                        </p>
                        <p style={{fontSize: "15px",color: "black",paddingBottom: "0px"}}>
                        <span style={{color: "#F3AD1C",fontSize: "8px"}}>+0.05%</span><span style={{color: "grey",fontSize:"8px"}}>than Budgeted</span>
                        </p>
                    </div>
                    <Opexlinechart></Opexlinechart>
                 </div>
             </div>
             <div className="col mt-2 mb-2">
                <hr></hr>
                    <div className=" card_custom">
                        <div>
                        <p style={{fontSize: "13px",color: "black",textAlign: "left"}}>
                        <FontAwesomeIcon icon={faMoneyBill} className="mr-1"></FontAwesomeIcon>
                        <b style={{marginLeft: "6px"}}>Daily Running Cost</b>
                        </p>
                        </div>
                    </div>
             </div>
            </section>
        </div>
</>
);
    }
}