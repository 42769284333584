import React from "react";
import {Row,Col,Button} from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 import { faRandom } from '@fortawesome/free-solid-svg-icons';
export default class Breadcrumbs extends React.Component {
    render() {
        return (
            <>
                
                <Row>
                <Col md={12} className="mt-5">
                  
                <Breadcrumb className="crumb" style={{  fontSize: "11px", backgroundColor: "offwhite" }}>
                    <Breadcrumb.Item href="#" style={{paddingLeft:"0px"}}><b>Home</b></Breadcrumb.Item>
                </Breadcrumb>
                </Col>
                </Row>
                
                
                <Row>
                        <Col md={8} xs={4} lg={10}>
                            <h1 className="crumb" style={{ fontWeight: "bolder", fontSize: "16px" }}><b>Crew</b></h1>
                        </Col>
                        <Col md={4} xs={8} lg={2}>
                            <FontAwesomeIcon icon={faRandom} className="sidemenu pt-1"></FontAwesomeIcon><Button variant="outline-gray"
                                className="my" style={{ fontSize: "8px", marginLeft: "10px", border: "none",padding:"5px"}}><b>SWITCH
                                            TO TIMELINE VIEW</b></Button>
                                            </Col>
                    </Row>

                

            </>
        );
    }
}
