import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome,faUsers,faCopy,faBullhorn,faDollarSign} from '@fortawesome/free-solid-svg-icons';
// import {MDBCol,MDBContainer,MDBRow} from "mdbreact";
export default class Sidebar extends React.Component {
  render() {
    return (
        <>
        <Container fluid>
            <Row>
        <Col md={1}>
            <div className="left-sidebar d-none d-md-block">
                <ul className="sidenav shadow">
                <li className="sidemenu"><a href="/" ><FontAwesomeIcon icon={faHome} style={{ width: "20px", height: "20px"}} /></a></li>
                <li className="sidemenu"><FontAwesomeIcon icon={faUsers} style={{ width: "20px", height: "20px"}} /></li>
                <li className="sidemenu"><a href="/financeupdated" ><FontAwesomeIcon icon={faDollarSign} style={{ width: "20px", height: "20px"}} /></a></li>
                <li className="sidemenu"><FontAwesomeIcon icon={faHome} style={{ width: "20px", height: "20px"}} /></li>
                <li className="sidemenu"><a href="/opex" ><FontAwesomeIcon icon={faCopy} style={{ width: "20px", height: "20px"}} /></a></li>
                <li className="sidemenu"><a href="/finance" ><FontAwesomeIcon icon={faHome} style={{ width: "20px", height: "20px"}} /></a></li>
                <li className="sidemenu"><a href="/vessel" ><FontAwesomeIcon icon={faBullhorn} style={{ width: "20px", height: "20px"}} /></a></li>
                </ul>
            </div>
          
          </Col>
          </Row>
        </Container>
       
        </>
    );
  }
}