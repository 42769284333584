import React from "react";
import Logo from "./images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ListGroup, Image, Dropdown} from "react-bootstrap";
// import { Dropdown } from "bootstrap";
export default class Togglesidebar extends React.Component {
    render() {
        return (<div className="sidebar">


            <ListGroup>
                <ListGroup.Item className="pt-4 text-decoration-none " style={{ fontSize: "13px", color: "white" }}><Image className="mx-1 my-1"
                    style={{ width: "20px", height: "20px", marginRight: "0px" }}
                    src={Logo} />ANGLO-EASTERN
            </ListGroup.Item>
                <ListGroup.Item className="pt-4 pb-3" style={{ borderBottom: ".5px solid gray" }}><div
                    className="text-decoration-none"
                    style={{ fontSize: "10px", color: "white", padding: ".5rem" }}>DASHBOARD<FontAwesomeIcon
                        icon={faAngleRight} style={{ marginLeft: "1rem" }}></FontAwesomeIcon></div></ListGroup.Item>

                {/* <ListGroup.Item className="pt-3 pb-3" style={{borderBottom: ".5px solid gray"}}><div
                        style={{color:"white",fontSize: "10px",padding: ".5rem"}}
                        className="nav-link active dropdown-toggle navbar-brand " 
                        id="navbardrop" data-toggle="dropdown">CREW</div>
                        <div className="dropdown-menu">
                            <ListGroup>
                                <ListGroup.Item><div className="text-decoration-none"
                                    style={{fontSize: "10px",color: "white",padding: ".5rem"}}>Crew on
                                Board</div>
                                    <div className="text-decoration-none"
                                        style={{fontSize: "10px",color: "white",padding: ".5rem"}}>Crew
                                List</div>

                                </ListGroup.Item>
                            </ListGroup>
                        </div><FontAwesomeIcon icon={faAngleRight}
                            style={{marginLeft: "1rem",color:"white",fontSize:"10px"}}></FontAwesomeIcon>
                    </ListGroup.Item> */}
                <ListGroup className="pt-3 pb-3" style={{ borderBottom: ".5px solid gray" }}>
                    <Dropdown>
                            <Dropdown.Toggle variant=" #003963" id="dropdown-basic" className="nun"
                             style={{color:"white",fontSize:"10px"}}>
                                Crew
                            </Dropdown.Toggle>

                            <Dropdown.Menu style={{backgroundColor:"#003963",borderBlock:".5px solid gray"}}>
                                <Dropdown.Item href="#/action-1">Crew on Board</Dropdown.Item>
                                <Dropdown.Item href="#/action-2">Crew</Dropdown.Item>
                                
                            </Dropdown.Menu>
                            <FontAwesomeIcon icon={faAngleRight}
                            style={{marginLeft: ".5rem",color:"white",fontSize:"10px"}}></FontAwesomeIcon>
                        </Dropdown>
                
                </ListGroup>
                <ListGroup.Item className="pt-3 pb-3" style={{ borderBottom: ".5px solid gray" }}><div
                    className="text-decoration-none"
                    style={{ fontSize: "10px", color: "white", padding: ".5rem" }}>FINANCIAL
                    PERFORMANCE<FontAwesomeIcon icon={faAngleRight}
                        style={{ marginLeft: " 1.5rem" }}></FontAwesomeIcon></div></ListGroup.Item>
                <ListGroup.Item className="pt-3 pb-3" style={{ borderBottom: ".5px solid gray" }}><div
                    className="text-decoration-none"
                    style={{ fontSize: "10px", color: "white", padding: ".5rem" }}>KPIs<FontAwesomeIcon
                        icon={faAngleRight} style={{ marginLeft: "8rem" }}></FontAwesomeIcon></div>
                </ListGroup.Item>
                <ListGroup.Item className="pt-3 pb-3" style={{ borderBottom: ".5px solid gray" }}><div
                    className="text-decoration-none"
                    style={{ fontSize: "10px", color: "white", padding: ".5rem" }}>DOCUMENTS<FontAwesomeIcon
                        icon={faAngleRight} style={{ marginLeft: "1.5rem" }}></FontAwesomeIcon></div>
                </ListGroup.Item>
                <ListGroup.Item className="pt-3 pb-3" style={{ borderBottom: ".5px solid gray" }}><div
                    className="text-decoration-none"
                    style={{ fontSize: "10px", color: "white", padding: ".5rem" }}>DRAWING/PLANS<FontAwesomeIcon
                        icon={faAngleRight} style={{ marginLeft: "4rem" }}></FontAwesomeIcon></div></ListGroup.Item>
                <ListGroup.Item className="pt-3 pb-3" style={{ borderBottom: ".5px solid gray" }}><div
                    className="text-decoration-none"
                    style={{ fontSize: "10px", color: "white", padding: ".5rem" }}>DRAWINGS<FontAwesomeIcon
                        icon={faAngleRight} style={{ marginLeft: "5.5rem" }}></FontAwesomeIcon></div></ListGroup.Item>
                <ListGroup.Item className="pt-2 pb-3"><div className="text-decoration-none"
                    style={{ fontSize: "7px", color: "white", padding: ".5rem" }}>Last updated 22-06-2020
                    at 12:56pm</div></ListGroup.Item>
            </ListGroup>

        </div>
        );
    }
}