import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Breadcrumbs from "./Breadcrumbs";
import Cards from "./Cards";
import Filters from "./Filters";
import Datatables from "./Datatables";
export default class Home extends React.Component {
    render() {
        return (
            <>
                <Container fluid>
                    
                    <Header></Header>
                   
                    <Row >
                        <Col md={1}>
                            <Sidebar></Sidebar>
                        </Col>

                        <Col md={11}>
                            <div className="htt">
                            <Row className="mt-2">
                                <Col md={12} >
                            <Breadcrumbs></Breadcrumbs>
                            </Col>
                            </Row>
                            <Row >
                                <Col md={12}>
                                    <Cards></Cards>
                                </Col>
                            </Row>
                            <Row>
                            <Filters></Filters>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Datatables></Datatables>
                                </Col>
                            </Row>
                            </div>
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }
}

