import React from "react";
import { Card, Row, Col } from "react-bootstrap";
export default class Cards extends React.Component {
  
     
   
    render() {
        return (
            <>
                <Row style={{ marginLeft: 0, marginRight: 0 }} className="pt-1">
                    
                <Col className="mine">
                            <Card className="shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Last Crew Change</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>SAV, USA</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Savannah, United States</p>
                                </div>
                            </Card>
                        </Col>
                    <Col className="mine">
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Upcoming Crew Change</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>JP, TOK</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Tokyo, Japan</p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="mine">
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Total crew onboard </p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>19</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>
                                        <span style={{color: "#F3AD1C",paddingRight: "4px"}}>5%</span>above
                                            average
                                        </p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="mine">
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Upcoming Crew Relief</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>8</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>2 more than last month</p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="mine">
                            <Card className="shadow">
                                <div className="card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Overdue Crew Change</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><span
                                        style={{color: "#F3AD1C"}}><b>5</b></span>
                                    </p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}><span
                                        style={{color: "#F3AD1C",paddingRight: "4px"}}>5%</span>above
                                            average</p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="mine">
                            <Card className="shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Open Medical Cases</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>0</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}><span
                                        style={{color:"skyblue",paddingRight:"4px"}}>-15%
                                            </span>below last year</p>
                                </div>
                            </Card>
                        </Col>
                    
                        <Col className = "mine">
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{ fontSize: "11px", color: "grey", marginBottom: "10px" }}>Crew Budget Change</p>
                                    <p style={{ fontSize: "15px", color: "black", marginBottom: "10px" }}><b>-$10,223</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}><span
                                        style={{color:"skyblue",paddingRight:"4px"}}>-10%
                                            </span>saved vs last month</p>
                                   
                                </div>
                            </Card>
                        </Col>
                </Row> 


            </>

        );
    }
}