import React from "react";
import { Card, Row, Col } from "react-bootstrap";
export default class Opexcards extends React.Component {
       render() {
        return (
            <>
                <Row style={{ marginLeft: 0, marginRight: 0 }} className="pt-1">
                
                        <Col className="mine">
                            <Card className="shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Budgeted</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>$126,000.50</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}><span style={{color: "#F3AD1C",paddingRight: "4px"}}>4%</span>above
                                            annual budget
                                    </p>
                                </div>
                            </Card>
                        </Col>
                     <Col className="mine">
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Stores</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>$18,638.30</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}> <span style={{color: "#F3AD1C",paddingRight: "4px"}}>2.2%%</span>above
                                            budget
                                    </p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="mine">
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Spares</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>$7,638.67</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>
                                        <span style={{color: "#F3AD1C",paddingRight: "4px"}}>2.2%</span>above
                                            budget
                                        </p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="mine">
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Manning</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>$12,405.00</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>
                                        <span style={{color: "#03e8fc",paddingRight: "4px"}}>0.5%</span>below
                                            budget
                                        </p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="mine">
                        <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Repairs</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>$1,888.50</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>
                                        <span style={{color: "#03e8fc",paddingRight: "4px"}}>+3.5%</span>below
                                            budget
                                        </p>
                                </div>
                            </Card>
                        </Col>
                        <Col className="mine">
                            <Card className="shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}>Extraordinary Others</p>
                                    <p style={{fontSize: "15px",color: "black",marginBottom: "10px"}}><b>$2,432.70</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}><span
                                        style={{color:"#03e8fc",paddingRight:"4px"}}>4%
                                            </span>below budget</p>
                                </div>
                            </Card>
                        </Col>
                    
                        <Col className="mine">
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                    <p style={{ fontSize: "11px", color: "grey", marginBottom: "10px" }}>Total Variance</p>
                                    <p style={{ fontSize: "15px", color: "black", marginBottom: "10px" }}><b>+$15,886.83</b></p>
                                    <p style={{fontSize: "11px",color: "grey",marginBottom: "10px"}}><span
                                        style={{color:"#03e8fc",paddingRight:"4px"}}>2.5%
                                            </span>lower than last month</p>
                                
                                </div>
                            </Card>
                        </Col>
                </Row>


            </>

        );
    }
}