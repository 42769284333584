import React from 'react'
import { Boxplot, computeBoxplotStats } from 'react-boxplot'

const values = [
   22,
  25,
  20,
  19,
  27,
  64,
  23,
  17,
  20,
  18,
]
const Example = () => (
  <Boxplot  
    width={950}
    height={100}
    orientation="horizontal"
    min={20}
    max={33}
	
    stats={computeBoxplotStats(values)}
  />
)
export default Example;