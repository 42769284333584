import React from 'react';
import { MDBDataTable } from 'mdbreact';
const Externalinspections = () => {
  const data = {
    columns: [
      { 
        label: 'Port',
        field: 'port',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Inspection Type',
        field: 'inspectiontype',
        sort: 'asc',
        width: 300
      },
      {
        label: 'Inspector',
        field: 'inspector',
        sort: 'asc',
        width: 100
      },
      {
        label: 'From',
        field: 'from',
        sort: 'asc',
        width: 100
      },
      {
        label: 'To',
        field: 'to',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Next Due',
        field: 'nextdue',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100
      },
     
    ],
    rows: [
    
      {
        port: '2021 May',
        inspectiontype: '28 May 2021 21:12',
        inspector: 'John',
        from: 'File Folder',
        to:'',
        nextdue:'',
        action: '',
        
      },
      {
        port: '2021 April',
       inspectiontype: '28 April 2021 21:12',
        inspector: 'John',
        from: 'File Folder',
        to:'',
        nextdue:'',
        action: '',
        
      },
      {
        port: '2021 March',
        inspectiontype: '28 March 2021 23:10',
        inspector: 'Michael',
        from: 'File Folder',
        to:'',
        nextdue:'',
        action: '',
        
      },
       {
        port: 'M.Sc',
        inspectiontype: '05 June 2021 23:10',
        inspector: 'Michael',
        from: 'File Folder',
        to:'',
        nextdue:'',
        action: '',
        
      },
     
     
     
     
    ]
  };

  return (
    <MDBDataTable
      small
      data={data}
      paging = {false}
      searching = {false}
      
    />
  );
}

export default Externalinspections;