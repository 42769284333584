import React from "react";
import {Row,Col} from "react-bootstrap";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faRandom } from '@fortawesome/free-solid-svg-icons';
export default class Opexbreadcrumb extends React.Component {
    render() {
        return (
            <>
                <Row>
                <Col md={12} className="mt-5">
                  
                <Breadcrumb className="crumb" style={{  fontSize: "11px", backgroundColor: "offwhite"}}>
                    <Breadcrumb.Item href="#" style={{paddingLeft:"0px"}}><b style={{color:"#03e8fc"}}>Home <span style={{paddingLeft:"4px",paddingRight:"4px"}}>/ </span>Financial Overview</b></Breadcrumb.Item>
                </Breadcrumb>
                </Col>
                </Row>
                <Row>
                    <Col md={8} xs={4} lg={10}>
                        <h1 className="crumb" style={{ fontWeight: "bolder", fontSize: "16px" }}><b>OPEX Variance</b></h1>
                    </Col>
                    <Col md={4} xs={8} lg={2}>
                        <p style={{fontSize:"10px", color:"grey"}}>Oldenorff Limited <span style={{paddingLeft:"4px",paddingRight:"4px"}}>| </span>Vessel Code 361X</p>
                            
                    </Col>
                    </Row>
            </>
        );
    }
}
