import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Financebreadcrumb from "./Financebreadcrumb";
import Financecard from "./Financecard";
import Financesection from "./Financesection";
export default class Financeupdated extends React.Component {
    render() {
        return (
            <>
                <Container fluid>
                    
                    <Header></Header>
                   
                    <Row >
                        <Col lg={1} md={1}>
                            <Sidebar></Sidebar>
                        </Col>

                        <Col lg={11} md={11}>
                            {/* <div className="htt">
                            <Row className="mt-2">
                                <Col md={9} > */}
                            {/* <Financebreadcrumb></Financebreadcrumb>
                            </Col>
                            </Row> */}
                              <Row className="mt-2">
                                <Col lg={9} md={12} >
                                <Financebreadcrumb></Financebreadcrumb>
                                    <Financecard></Financecard>
                                </Col>
                                <Col lg={3} md={11}>
                                    <Financesection></Financesection>
                                    </Col>
                            </Row>
                          {/* </div> */}
                    </Col> 
                    </Row>

                </Container>

            </>
        );
    }
}

