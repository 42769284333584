import React, { Component } from 'react';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";


am4core.useTheme(am4themes_animated);

export default class Opexvariancechart extends Component {
    componentDidMount() {
        var chart = am4core.create("chartdiv5", am4charts.XYChart);

        // Add data
        chart.data = [{
            "date": new Date(2019, 1, 20),
            "value": 3
        },
        {
            "date": new Date(2019, 2, 3),
            "value": -2
        },
        {
            "date": new Date(2019, 3, 21),
            "value": 4
        },
        {
            "date": new Date(2019, 4, 6),
            "value": -6
        },
        {
            "date": new Date(2019, 5, 22),
            "value": 8
        }, 
        {
            "date": new Date(2019, 6, 11),
            "value": -7
        },
        {
            "date": new Date(2019, 7, 23),
            "value": 10
        }, 
        {
            "date": new Date(2019, 8, 8),
            "value": 13
        },
        {
            "date": new Date(2019, 9, 24),
            "value": -11
        },
        {
            "date": new Date(2019, 10, 5),
            "value": 15
        },
        {
            "date": new Date(2019, 11, 25),
            "value": 17
        },
        {
            "date": new Date(2019, 12, 20),
            "value": -7
        }
        ];

        // Create axes
        var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.fontSize=12;
        dateAxis.renderer.minGridDistance = 25;
        // Create value axis
        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.fontSize=12;
        // Create series
        var lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.stroke = am4core.color("#808080");
        lineSeries.dataFields.valueY = "value";
        lineSeries.dataFields.dateX = "date";
        //lineSeries.name = "Month";
        lineSeries.strokeWidth = 1;

        // Add simple bullet
        var bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
        var image = bullet.createChild(am4core.Image);
        image.width = 10;
        image.height = 10;
        image.horizontalCenter = "middle";
        image.verticalCenter = "middle";

    




    // Add cursor
    chart.cursor = new am4charts.XYCursor();

    // Add legend
    chart.legend = new am4charts.Legend();

// let scrollbarX = new am4charts.XYChartScrollbar();
// scrollbarX.series.push(series);
// chart.scrollbarX = scrollbarX;
    
this.chart = chart;
  }


componentWillUnmount() {
    if (this.chart) {
        this.chart.dispose();
    }
}

render() {
    return (
        <div id="chartdiv5" style={{ width: "100%", height: "200px", backgroundColor: "white" }}></div>
    );
}
}

