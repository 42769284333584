import React from 'react';
import { MDBDataTable } from 'mdbreact';
const Otherreports = () => {
  const data = {
    columns: [
      { 
        label: 'Name',
        field: 'name',
        sort: 'asc',
        width: 150
      },
      {
        label: 'Date Modified',
        field: 'datemodified',
        sort: 'asc',
        width: 270
      },
      {
        label: 'Modified By',
        field: 'modifiedby',
        sort: 'asc',
        width: 200
      },
      {
        label: 'Type',
        field: 'type',
        sort: 'asc',
        width: 100
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 150
      },
     
    ],
    rows: [
    
      {
        name: '2021 May',
        datemodified: '28 May 2021 21:12',
        modifiedby: 'John',
        type: 'File Folder',
        action: '',
        
      },
      {
        name: '2021 April',
        datemodified: '28 April 2021 21:12',
        modifiedby: 'John',
        type: 'File Folder',
        action: '',
        
      },
      {
        name: '2021 March',
        datemodified: '28 March 2021 23:10',
        modifiedby: 'Michael',
        type: 'File Folder',
        action: '',
        
      },
       {
        name: 'M.Sc',
        datemodified: '05 June 2021 23:10',
        modifiedby: 'Michael',
        type: 'File Folder',
        action: '',
        
      },
     
     
     
     
    ]
  };

  return (
    <MDBDataTable
      small
      data={data}
      paging = {false}
      searching = {false}
      
    />
  );
}

export default Otherreports;