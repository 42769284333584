import React from "react";
import { Table, Row,Pagination } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from '@fortawesome/free-solid-svg-icons';
import $ from "jquery";
import Timeline from "./Timeline";
export default class Datatables extends React.Component {
    tabdata = [
        {
        id: 0,
        staff:"1D923",
        fname: "Enrico",
        lname: "Villanjeva",
        rank: "Master",
        age: "41",
        nationality: "ESP",
        Sdate: "10 Nov 2019",
        Edate : "20 Aug 2020",
        status: "EOC Due",
        
    },
    {
        id: 1,
        staff:"1D029",
        fname: "Pilapil",
        lname: "JONAS",
        rank: "Master",
        age: "37",
        nationality: "PHL",
        Sdate: "20 Dec 2020",
        Edate : "20 Aug 2020",
        status: "Relief Due",
    },
    {
        id: 2,
        staff:"1D029",
        fname: "Suria",
        lname: "JAY",
        rank: "Chief Officer",
        age: "38",
        nationality: "IND",
        Sdate: "10 Nov 2020",
        Edate : "20 Aug 2020",
        status: "Onboard",
    },
    {
        id: 3,
        staff:"18289",
        fname: "Lazora",
        lname: "CHRISTIAN JON",
        rank: "2nd officer",
        age: "33",
        nationality: "PHL",
        Sdate: "10 Nov 2019",
        Edate : "20 Aug 2020",
        status: "Onboard",
    },
    {
        id: 4,
        staff:"12983",
        fname: "Pinuela",
        lname: "RONALD",
        rank: "3rd officer",
        age: "37",
        nationality: "PHL",
        Sdate: "10 Nov 2019",
        Edate : "20 Aug 2020",
        status: "Relief Due",
    },
    {
        id: 5,
        staff:"12329",
        fname: "Egano",
        lname: "GARY",
        rank: "Chief Engineer",
        age: "35",
        nationality: "PHL",
        Sdate: "10 Nov 2019",
        Edate : "20 Aug 2020",
        status: "Relief Due",
    },
    {
        id: 6,
        staff:"12329",
        fname: "Beraquit",
        lname: "MARCK ANTON",
        rank: "2nd Engineer",
        age: "35",
        nationality: "PHL",
        Sdate: "10 Nov 2019",
        Edate : "20 Aug 2020",
        status: "Relief Due",
    },
    {
        id: 7,
        staff:"12329",
        fname: "Ricana",
        lname: "JONAS",
        rank: "3rd Engineer",
        age: "26",
        nationality: "PHL",
        Sdate: "10 Nov 2019",
        Edate : "20 Aug 2020",
        status: "Onboard",
    },
    {
        id: 8,
        staff:"12329",
        fname: "Kurana",
        lname: "ANGELICO",
        rank: "3rd Engineer",
        age: "31",
        nationality: "PHL",
        Sdate: "10 Nov 2019",
        Edate : "20 Aug 2020",
        status: "Relief Due",
    },
    {
        id: 9,
        staff:"12329",
        fname: "Eden",
        lname: "LAZARDO",
        rank: "TREO",
        age: "27",
        nationality: "PHL",
        Sdate: "10 Nov 2019",
        Edate : "20 Aug 2020",
        status: "Injured",
    },
];
componentDidMount()
{
$('.new2').hide();
$('.my').on('click',
function () {
    $('.new1, .new2').toggle(100);
}
);
} 

   render() {
        return (<>
                <Row style={{ marginLeft: 0, marginRight: 0 }} className="pt-1 shadow new1">
                  <Table responsive hover variant="light">
                        <thead>
                            <tr>
                                <th>Staff #</th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Rank</th>
                                <th>Age</th>
                                <th>Nationality</th>
                                <th>Sign-On-Date</th>
                                <th>EOC DATE</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                        {this.tabdata.map(tabelement=>(
                           
                            <tr>
                                <td>{tabelement.staff}</td>
                                <td>{tabelement.fname}</td>
                                <td>{tabelement.lname}</td>
                                <td>{tabelement.rank}</td>
                                <td>{tabelement.age}</td>
                                <td>{tabelement.nationality}</td>
                                <td>{tabelement.Sdate}</td>
                                <td>{tabelement.Edate}</td>
                                <td><FontAwesomeIcon icon={faBell} style={{ marginRight: "5px" }} className="temp" />{tabelement.status}
                                                </td> 
                            </tr>
                            ))} 
                            {/* <tr>
                                <td>1D029</td>
                                <td>Pilapil</td>
                                <td>Jonas</td>
                                <td>Master</td>
                                <td>37</td>
                                <td>PHL</td>
                                <td>20 Aug 2020</td>
                                <td>20 Dec 2020</td>
                                <td><FontAwesomeIcon icon={faBell} style={{ marginRight: "5px", color: "skyblue" }} />Relief Due</td>
                            </tr>
                            <tr>
                                <td>1D209</td>
                                <td>Suris</td>
                                <td>Jay</td>
                                <td>Chief Officer</td>
                                <td>38</td>
                                <td>IND</td>
                                <td>10 Nov 2019</td>
                                <td>20 Aug 2020</td>
                                <td><FontAwesomeIcon icon={faCircle} style={{ marginRight: "5px", color: "03e8fc" }} />Onboard</td>
                            </tr>
                            <tr>
                                <td>1B289</td>
                                <td>Lazora</td>
                                <td>Christian Jon</td>
                                <td>2nd Officer</td>
                                <td>33</td>
                                <td>PHL</td>
                                <td>10 Nov 2019</td>
                                <td>20 Aug 2020</td>
                                <td><FontAwesomeIcon icon={faCircle} style={{ marginRight: "5px", color: "03e8fc" }} />Onboard</td>
                            </tr>
                            <tr>
                                <td>12983</td>
                                <td>Pineula</td>
                                <td>Ronald</td>
                                <td>3rd Officer</td>
                                <td>37</td>
                                <td>PHL</td>
                                <td>10 Nov 2019</td>
                                <td>20 Aug 2020</td>
                                <td><FontAwesomeIcon icon={faBell} style={{ marginRight: "5px", color: "skyblue" }} />Relief Due</td>
                            </tr>
                            <tr>
                                <td>12329</td>
                                <td>Egano</td>
                                <td>Gary</td>
                                <td>Chief Engineer</td>
                                <td>35</td>
                                <td>PHL</td>
                                <td>10 Nov 2019</td>
                                <td>20 Aug 2020</td>
                                <td><FontAwesomeIcon icon={faBell} style={{ marginRight: "5px", color: "skyblue" }} />Relief Due</td>
                            </tr>
                            <tr>
                                <td>12329</td>
                                <td>Beraquit</td>
                                <td>Mark Aniton</td>
                                <td>2nd Engineer</td>
                                <td>35</td>
                                <td>PHL</td>
                                <td>10 Nov 2019</td>
                                <td>20 Aug 2020</td>
                                <td><FontAwesomeIcon icon={faBell} style={{ marginRight: "5px", color: "skyblue" }} />Relief Due</td>
                            </tr>
                            <tr>
                                <td>12983</td>
                                <td>Ricana</td>
                                <td>Jonas</td>
                                <td>3rd Engineer</td>
                                <td>25</td>
                                <td>PHL</td>
                                <td>10 Nov 2019</td>
                                <td>20 Aug 2020</td>
                                <td><FontAwesomeIcon icon={faCircle} style={{ marginRight: "5px", color: "03e8fc" }} />Onboard</td>
                            </tr>
                            <tr>
                                <td>12983</td>
                                <td>Kurana</td>
                                <td>Angelico</td>
                                <td>3rd Engineer</td>
                                <td>31</td>
                                <td>PHL</td>
                                <td>10 Nov 2019</td>
                                <td>20 Aug 2020</td>
                                <td><FontAwesomeIcon icon={faBell} style={{ marginRight: "5px", color: "skyblue" }} />Relief Due</td>
                            </tr>
                            <tr>
                                <td>12983</td>
                                <td>Eoen</td>
                                <td>Lazardo</td>
                                <td>TREO</td>
                                <td>27</td>
                                <td>PHL</td>
                                <td>10 Nov 2019</td>
                                <td>20 Aug 2020</td>
                                <td><FontAwesomeIcon icon={faMedkit} style={{ marginRight: "5px" }} className="temp" />Injured</td>
                            </tr> */}
                        </tbody>
                    </Table>
                   
               
                <Pagination className="pt-1 float-right">
                    <Pagination.Item >{"Prev"}</Pagination.Item>
                    <Pagination.Item active>{1}</Pagination.Item>
                    <Pagination.Item>{"Next"}</Pagination.Item>
                </Pagination>
                </Row>
                <Row style={{ marginLeft: 0, marginRight: 0 }} className="pt-1 shadow new2">
                                {/* <p style={{color: "#F3AD1C"}}>This is timeline view section</p>
                                <p>my name is priya</p> */}
                                {/* <Row>
                                <Col md={12}> */}
                                <Timeline></Timeline>
                                {/* </Col>
                                </Row> */}
                    </Row>
                   
            </>
        );
    }
}
