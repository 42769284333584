import React, { Component } from 'react'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);
export default class Timeline extends Component {
    //componentDidMount
    componentDidMount() {
        let chart = am4core.create("chartdiv", am4charts.XYChart);
        chart.hiddenState.properties.opacity = 0; 
    // this creates initial fade-in
        chart.maxZoomLevel = 1;

        chart.paddingRight = 40;
        
        chart.dateFormatter.inputDateFormat = "yyyy-MM-dd";

        let colorSet = new am4core.ColorSet();
        colorSet.saturation = 0.4;

        // chart data
        chart.data = [
            {
                name: "ENRICO VILLANAUE",
                bullet: "https://www.amcharts.com/lib/images/faces/A04.png",
                fromDate: "2018-01-01",
                toDate: "2018-08-12",
                color: "#BFDEE6",
                category_name: "MASTER"
            },
            {    
                parent: "ENRICO VILLANAUE", 
                name: "Injury",
                fromDate:"2018-04-05",
                toDate: "2018-04-30",
				color: "#F3AD1C",
                category_name: ""

            },
            {
                parent: "ENRICO VILLANAUE",
                name: "PILAPIL JONAS",
                bullet: "https://www.amcharts.com/lib/images/faces/A04.png",
                fromDate: "2018-05-20",
                toDate: "2018-12-12",
                color: "#BFDEE6",
                category_name: ""
            },


            {
                name: "SHARMA JAY",
                bullet: "https://www.amcharts.com/lib/images/faces/A04.png",
                fromDate: "2018-01-01",
                toDate: "2018-08-30",
                color: "#BFDEE6",
                category_name: "CHIEF OFFICER"
            },
            {    
                parent: "SHARMA JAY", 
                name: "Injury",
                fromDate:"2018-06-05",
                toDate: "2018-06-30",
                color: "#F3AD1C",
                category_name: "NEW2"

            },
            {
                parent: "CHIEF OFFICER",
                name: " Nikalson",
                bullet: "https://www.amcharts.com/lib/images/faces/A04.png",
                fromDate: "2018-04-20",
                toDate: "2018-11-12",
                  color: "#BFDEE6",
                category_name: "NEW"
            },

            {
                name: "PILAPIL JONAS",
                bullet: "https://www.amcharts.com/lib/images/faces/A04.png",
                fromDate: "2018-07-16",
                toDate: "2019-08-20",
                color: "#BFDEE6",
                category_name: "2nd OFFICER"
            },
            {
                name: "PINEULA JONAS",
                bullet: "https://www.amcharts.com/lib/images/faces/A04.png",
                fromDate: "2018-03-05",
                toDate: "2019-02-18",
              color: "#BFDEE6",
                category_name: "3rd OFFICER"
            },

            {
                name: "EGANO GARY",
                bullet: "https://www.amcharts.com/lib/images/faces/A04.png",
                fromDate: "2018-11-06",
                toDate: "2019-09-01",
                color: "#BFDEE6",
                category_name: "CHIEF ENGINEER"
            },
            {
                name: "SUFIA JAY",
                bullet: "https://www.amcharts.com/lib/images/faces/A04.png",
                fromDate: "2018-03-01",
                toDate: "2019-02-08",
                color: "#BFDEE6",
                category_name: "2nd ENGINEER"
            },
];
        // categoryAxis
        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
         categoryAxis.dataFields.category = "category_name";
        categoryAxis.fontSize = 12;
        categoryAxis.fontWeight = "bold";
        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 10;
        categoryAxis.renderer.inversed = true;
        categoryAxis.renderer.grid.template.disabled = true;
		
        // dateAxis
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd";
        dateAxis.fontSize = 12;
        dateAxis.fontWeight = "bold";
        dateAxis.baseInterval = { count: 1, timeUnit: "day" };
        dateAxis.renderer.tooltipLocation = 4;
        dateAxis.renderer.grid.template.disabled = true;
        // Series
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.columns.template.width = am4core.percent(80);
        series.columns.template.height = am4core.percent(90);
        series.columns.template.column.cornerRadiusTopLeft = 15;
        series.columns.template.column.cornerRadiusTopRight = 15;
        series.columns.template.column.cornerRadiusBottomLeft = 15;
        series.columns.template.column.cornerRadiusBottomRight = 15;
        series.columns.template.tooltipText = "{category_name}:{openDateX} - {dateX}";
        series.dataFields.text = "h";
        series.dataFields.openDateX = "fromDate";
        series.dataFields.dateX = "toDate";
        series.dataFields.categoryY = "category_name";
        series.columns.template.propertyFields.fill = "color"; // get color from data
        series.columns.template.propertyFields.stroke = "color";
        series.columns.template.strokeOpacity = 1;
        
        // Category Label

        var categoryLabel = series.bullets.push(new am4charts.LabelBullet());
        categoryLabel.label.text = "{category_name} \n [bold]{name}";
        categoryLabel.label.fontSize =11;
        categoryLabel.locationX = 0.14;
		categoryLabel.paddings=20;
        categoryLabel.label.fill = am4core.color("#4E77A6");
		var chartv = "{category_name}";
		console.log(series.columns.template.tooltipText);
        let bullet = series.bullets.push(new am4charts.CircleBullet());
        bullet.circle.radius = false;
        bullet.locationX = 0.99;
        let image = bullet.createChild(am4core.Image)
        image.verticalCenter = "bottom";
        image.dy = 9;
        image.width = 20;
        image.height = 20;
        image.y = am4core.percent(10);
        image.propertyFields.href = "bullet";
        image.tooltipText = series.columns.template.tooltipText;
        image.propertyFields.fill = "color";
        image.filters.push(new am4core.DropShadowFilter());
        chart.responsive.enabled = true;
        chart.scrollbarX = new am4core.Scrollbar();
      
        this.chart = chart;
		
    }
    // componentwillUnmount

    componentWillUnmount() {
        if (this.chart) {
            this.chart.dispose();
        }
    }
    // render
    render() {
        return (
            <div id="chartdiv" style={{ width: "100%", height: "500px" }}></div>
        );
    }
}