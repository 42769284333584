import React from "react";
import { Row, Col, Badge,Dropdown} from "react-bootstrap";
export default class Opexfilter extends React.Component {
    render() {
        return (<>
            <Row style={{ marginLeft: "0px" }} className="pt-1">
                <Col lg={12}>
                    <div className=" mt-2 mb-2">
                        <Badge pill className="tagsitme p-1 mr-2"
                            style={{ marginRight: "8px", fontSize: "10px", backgroundColor: "#00828A" }}>This Period</Badge>
                        <Badge pill variant="light" className="tagsitme p-1"
                            style={{ marginRight: "8px", fontSize: "10px", backgroundColor: "white", color: "grey" }}>YTD</Badge>
                    

                    {/* <Badge pill Dropdown.Toggle className=" btn dropdown-toggle  badge badge-pill tag_border p-1 mr-2"
                            data-toggle="dropdown"
                            style={{marginRight: "5px",fontSize: "10px",backgroundColor: "white",color:"grey"}}>Custom
                            DateRange</Badge> */}
                   <span className="  p-1 mr-1 badge badge-pill "> 
                   <Dropdown>
                   
                        <Dropdown.Toggle variant="light" id="button-basic" className="newbdage tagsitme "
                        style={{ marginRight: "8px", fontSize: "10px", backgroundColor: "white", color: "black" }}>
                            Custom Date Range
                       
                         </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                        </Dropdown.Menu>
                       </Dropdown>
                    </span>
                    </div>
                </Col>
            </Row>
            
        </>
        );
    }
}