import React from "react";
// import Otherreports from "./Otherreports";
 import Externalinspections from "./Externalinspections";
// import Opexreport from "./Opexreport";
import { Tab,Tabs} from "react-bootstrap";
export default class Vesseltabs extends React.Component {
    render() {
        return (<>
        {/* <Row style={{ marginLeft: "0px" }} className="pt-1">
            <Col lg={12}> */}
                <div className=" mt-1 mb-2">
                <Tabs defaultActiveKey="External Inspections">
                    <Tab eventKey="External Inspections" title="External Inspections">
                    <Externalinspections className="shadow"></Externalinspections>   
                    </Tab>
                    <Tab eventKey="Internal Inspections" title="Internal Inspections">
                       
                        This is Internal Inspections tab
                    </Tab>
                    <Tab eventKey="Gallery" title="Gallery"> 
                    This is Gallery tab
                    </Tab>
                    <Tab eventKey="Claims" title="Claims"> 
                    This is Claims tab
                    </Tab>
                    <Tab eventKey="Commercial" title="Commercial"> 
                    This is Commercial tab
                    </Tab>
                    <Tab eventKey="Other Reports" title="Other Reports"> 
                    This is Other Reports tab
                    </Tab>
                </Tabs>
                 </div>
            {/* </Col>
        </Row> */}
            </>
        );
    }
}