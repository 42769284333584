// import logo from './logo.svg';
// import './App.css';
// import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import {Route,Switch} from "react-router-dom";
import Home from "./components/Home";
import Opex from "./components/Opex";
import Financeupdated from "./components/Financeupdated";
import Finance from "./components/Finance";
import Error from "./components/Error";
import Vesselreports from "./components/Vesselreports";
export default class App extends React.Component{
render () {
  return (
 <Switch>
   <Route exact path="/" component={Home}/>
   
   <Route path="/financeupdated" component={Financeupdated}/>
   <Route path="/opex" component={Opex}/>
   <Route path="/finance" component={Finance}/>
   <Route path="/vessel"  component={Vesselreports}/>
   <Route component={Error}/>
 </Switch>
  );
}
}

