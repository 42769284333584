import React  from "react";
import { Container,Row, Col } from "react-bootstrap";
import Budgetchart from "./Budgetchart";
import Opexlinechart from "./Opexlinechart";
import Opexvariancechart from "./Opexvariancechart";
import Runningcostchart from "./Runningcostchart";
export default class Dashboard extends React.Component {
    render() {
        return (
            <Container fluid>
            <Row>
                <Col lg={6} md={6} className="mt-3">
                <div className="card card_custom boxmin">
                    <div className="card-body">
                        <p style={{fontSize: "8px",color: "grey"}}>Total Expenditure<span style={{float: "right"}}>See More</span></p>
                        <p style={{fontSize: "13px",color: "black"}}>
                        <b>Total Budget:$103,000.50</b>
                        </p>
                        <p style={{fontSize: "8px"}}> <span style={{color:"#03e8fc"}}>-12%</span><span style={{color:"grey",marginLeft:"2px"}}>THAN FORCASTED</span></p>
                    
                    <div className="plot">
                    <Row> 
                    
                        <Col lg={8} md={8}  className="mt-4">
                         <Budgetchart></Budgetchart>
                         </Col>
                        
                    </Row>
                </div>
                 </div>
                 </div>
                </Col>
                <Col lg={6} md={6} className="mt-3">
                <div className="card card_custom">
                    <div className="card-body">
                        <p style={{fontSize: "8px",color: "grey"}}>AVERAGE DAILY RUNNING COST AS OF JUNE 2020</p>
                        <p style={{fontSize: "13px",color: "black"}}>
                        <b>Total Budget:$4583</b>
                        </p>
                        <p style={{fontSize: "8px"}}> <span style={{color:"#03e8fc"}}>+0.75%</span><span style={{color:"grey",marginLeft:"2px"}}>THAN BUDGETED</span>
                        </p>
                    <div className="plot">
                    <Row> 
                    {/* <Col lg={2} md={2}>
                        </Col> */}
                        <Col lg={12} md={12}>
                         <Runningcostchart></Runningcostchart>
                         </Col>
                         {/* <Col lg={2} md={2}>
                        </Col> */}
                    </Row>
                </div>
                 </div>
                 </div>
                </Col>
                
            </Row>
            <Row>
                <Col lg={6} md={6} className="mt-3">
                <div className="card card_custom">
                    <div className="card-body">
                        <p style={{fontSize: "8px",color: "grey"}}>AVERAGE OPEX VARIANCE AS OF JUNE 2020</p>
                        <p style={{fontSize: "13px",color: "black"}}>
                        <b>Total Budget:$38,638.62</b>
                        </p>
                        <p style={{fontSize: "8px"}}><span style={{color: "#F3AD1C",fontSize: "8px"}}>+2.75%</span><span style={{color: "grey",fontSize:"8px"}}>THAN BUDGETED</span>
                        </p>
                    <div className="plot">
                    <Row> 
                    {/* <Col lg={2} md={2}>
                        </Col> */}
                        <Col lg={12} md={12}>
                         <Opexvariancechart></Opexvariancechart>
                         </Col>
                         {/* <Col lg={2} md={2}>
                        </Col> */}
                    </Row>
                </div>
                 </div>
                 </div>
                </Col>
                <Col lg={6} md={6} className="mt-3">
                <div className="card card_custom">
                    <div className="card-body">
                        <p style={{fontSize: "8px",color: "grey"}}><b>OPEX Variance</b><span style={{float: "right"}}>See More </span></p>
                        <p style={{fontSize: "13px",color: "black",marginBottom: "0"}}>
                        <b>Total Budget:$103,000.50</b>
                        </p>
                        <p style={{fontSize: "15px",color: "black",paddingBottom: "0px"}}>
                        <span style={{color: "#F3AD1C",fontSize: "8px"}}>+0.05%</span><span style={{color: "grey",fontSize:"8px"}}>than Budgeted</span>
                        </p>
                    </div>
                   
                    <Row> 
                    {/* <Col lg={2} md={2}>
                        </Col> */}
                        <Col lg={12} md={12}>
                         <Opexlinechart></Opexlinechart>
                         </Col>
                         {/* <Col lg={2} md={2}>
                        </Col> */}
                    </Row>
                    
                 </div>
            
                </Col>
                
            </Row>

                </Container>

        );
    }
}
