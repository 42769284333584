import React from "react";
import Logo from "./images/logo.png";
import Profile from "./images/profile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from "react-bootstrap";
import { Navbar, NavDropdown, Image } from "react-bootstrap";
import Togglesidebar from "./Togglesidebar";

export default class Header extends React.Component {
    sellang=[
        { id : 0,
        lang : "Hindi",
}, 
{ id : 1,
    lang: "English"
},
{ id : 2,
    lang : "Spanish"

},
{
    id : 3,
    lang : "French"
}
];
 selship =[
     { id : 0,
        shipname : "Birta Oldernorff",
        shipid :"IND7879",
},
{ id : 1,
    shipname : "Rixta Oldenorff",
    shipid :"IND7879",
},
{ id : 2,
    shipname : "Hubertus",
    shipid :"IND7879",
},
{ id : 3,
    shipname : "Henriette Oldenorff",
    shipid :"IND7879",
},
{ id : 4,
    shipname : "Regine Oldenorff",
    shipid :"IND7879",
},
{ id : 5,
    shipname : "Rixta Oldenorff",
    shipid :"IND7879",
},
{ id : 6,
    shipname : "Hanna Oldenorff",
    shipid :"IND7879",
},
{ id : 7,
    shipname : "Hubertus Oldenorff",
    shipid :"IND7879",
},
{ id : 8,
    shipname : "Henriette Oldenorff",
    shipid :"IND7879",
},
{ id : 9,
    shipname : "Regine Oldenorff",
    shipid :"IND7879",
},
{ id : 10,
    shipname : "Rixta Oldenorff",
    shipid :"IND7879",
},
];
    render() {
        return (
            <>
                <Navbar fixed="top" collapseOnSelect expand="lg" className="shadow pt-1 p-0 temp" >
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" >
                        <Togglesidebar></Togglesidebar> </Navbar.Collapse>
                    <Row style={{ width: "100%" }}>
                        <Col lg={10} md={9}>
                            <Row >
                                <Col lg={1} md={2} className="desn">
                                    {/* logo */}
                                    <Navbar.Brand href="#" className="cust">
                                        <Image src={Logo} style={{ width: "25px", height: "25px" }}></Image>
                                    </Navbar.Brand>
                                </Col>
                                {/* usericon */}
                                <Col lg={1} md={2} className="desn">
                                    <Navbar.Brand href="#" className="cust ">
                                        <FontAwesomeIcon icon={faUser} style={{ width: "20px", height: "20px", color: "#F3AD1C" }} />
                                    </Navbar.Brand>
                                </Col>
                                {/* select a ship dropdown */}
                                <Col lg={10} md={8} >
                                    <NavDropdown title="Select a ship" id="collasible-nav-dropdown" style={{ fontSize: "12px", fontWeight: "bolder" }} className="cust">
                                        <div style={{ width: "270px" }}>
                                        {this.selship.map(shipelement=>(
                                            <Row className="row border_custom" >
                                                <Col md={10}>
                                                    <label>{shipelement.shipname}<br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >{shipelement.shipid}</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                        ))}
                                            {/* <Row className="row border_custom" >
                                                <Col md={10}>
                                                    <label>Rixta Oldenorff
                                                        <br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >IND7879</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className="row border_custom" >
                                                <Col md={10}>
                                                    <label>Hubertus Oldenorff
                                                        <br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >IND7879</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className="row border_custom" >
                                                <Col md={10}>
                                                    <label>Henriette Oldenorff
                                                        <br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >IND7879</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className="row border_custom" >
                                                <Col md={10}>
                                                    <label>Regine Oldenorff
                                                        <br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >IND7879</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className="row border_custom" >
                                                <Col md={10}>
                                                    <label>Rixta Oldenorff
                                                        <br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >IND7879</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className="row border_custom" >
                                                <Col md={10}>
                                                    <label>Hanna Oldenorff
                                                        <br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >IND7879</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className="row border_custom" >
                                                <Col md={10}>
                                                    <label>Hubertus Oldenorff
                                                        <br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >IND7879</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className="row border_custom" >
                                                <Col md={10}>
                                                    <label>Henriette Oldenorff
                                                        <br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >IND7879</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className="row border_custom" >
                                                <Col md={10}>
                                                    <label>Regine Oldenorff
                                                        <br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >IND7879</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className="row border_custom test" >
                                                <Col md={10}>
                                                    <label>Rixta Oldenorff
                                                        <br /><span
                                                            style={{ color: "gray", fontSize: "7px" }} >IND7879</span></label>
                                                </Col>
                                                <Col><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row> */}
                                        </div>

                                    </NavDropdown>
                                </Col>

                            </Row>


                        </Col>
                        <Col lg={2} md={3}>
                            <Row className="cust">
                                <Col lg={7} md={8}>
                                    <NavDropdown title="Select Language" id="collasible-nav-dropdown" style={{ fontSize: "12px", fontWeight: "bolder" }}>
                                        <div>
                                        {this.sellang.map(selelement=>(
                           
                                            <Row className="row border_custom" >
                                                <Col md={9}>
                                                    <label>{selelement.lang}</label>
                                                </Col>
                                                <Col md={3}><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>))}
                                            {/* <Row className="row border_custom" >
                                                <Col md={9}>
                                                    <label>English</label>
                                                </Col>
                                                <Col md={3}><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className="row border_custom" >
                                                <Col md={9}>
                                                    <label>Spanish</label>
                                                </Col>
                                                <Col md={3}><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row>
                                            <Row className=" row border_custom test">
                                                <Col md={9}>
                                                    <label>French</label>
                                                </Col>
                                                <Col md={3}><input type="radio"
                                                    name="optradio" defaultChecked />
                                                </Col>
                                            </Row> */}
                                        </div>
                                    </NavDropdown>
                                </Col>
                                {/* profile icon */}
                                <Col lg={5} md={4}>
                                    <Navbar.Brand href="#">
                                        <Image src={Profile} roundedCircle style={{ width: "25px", height: "25px" }}></Image>
                                    </Navbar.Brand>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Navbar>
            </>
        );




    }
}
