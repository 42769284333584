import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Vesselbreadcrumb from "./Vesselbreadcrumb";

import Vesseltabs from "./Vesseltabs";
export default class Vesselreports extends React.Component {
    render() {
        return (
            <>
                <Container fluid>
                    
                    <Header></Header>
                   
                    <Row >
                        <Col md={1}>
                            <Sidebar></Sidebar>
                        </Col>

                        <Col md={11}>
                            <div className="htt">
                            <Row className="mt-2">
                                <Col md={12} >
                                    <Vesselbreadcrumb></Vesselbreadcrumb>
                            </Col>
                            </Row>
                            {/* <Row >
                                <Col md={12}>
                                    <Opexcards></Opexcards>
                                </Col>
                            </Row> */}
                            {/* <Row>
                            <Financefilter></Financefilter>
                            </Row> */}
                            <Row>
                             <Col md={12}>
                            <Vesseltabs></Vesseltabs>
                            </Col>
                            </Row>
                           
                            </div>
                        </Col>
                    </Row>

                </Container>

            </>
        );
    }
}

