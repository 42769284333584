import React from "react";
import Opexdatatable from "./Opexdatatable";
import { Tab,Tabs} from "react-bootstrap";
export default class Financetabs extends React.Component {
    render() {
        return (<>
        {/* <Row style={{ marginLeft: "0px" }} className="pt-1">
            <Col lg={12}> */}
                <div className=" mt-1 mb-2">
                <Tabs defaultActiveKey="OPEX-A">
                    <Tab eventKey="Opex-A" title="Opex-A">
                    <Opexdatatable className="shadow"></Opexdatatable>
                    </Tab>
                    <Tab eventKey="Opex-B" title="Opex-B">
                        This is Opex B
                    </Tab>
                   
                </Tabs>
                 </div>
            {/* </Col>
        </Row> */}
            </>
        );
    }
}