import React from "react";
import Otherreports from "./Otherreports";
import Dashboard from "./Dashboard";
import Opexreport from "./Opexreport";
import { Tab,Tabs} from "react-bootstrap";
export default class Financetabs extends React.Component {
    render() {
        return (<>
        {/* <Row style={{ marginLeft: "0px" }} className="pt-1">
            <Col lg={12}> */}
                <div className=" mt-1 mb-2">
                <Tabs defaultActiveKey="Dashboard">
                    <Tab eventKey="Dashboard" title="Dashboard">
                    <Dashboard className="shadow"></Dashboard>   
                    </Tab>
                    <Tab eventKey="Opex Report" title="Opex Report">
                        <Opexreport className="shadow"></Opexreport>
                    </Tab>
                    <Tab eventKey="Other Reports" title="Other Reports"> 
                    <Otherreports className="shadow"></Otherreports>
                    </Tab>
                </Tabs>
                 </div>
            {/* </Col>
        </Row> */}
            </>
        );
    }
}