import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
export default class Financecard extends React.Component {
       render() {
        return (
            <>
                <Row >
                
                        <Col lg={4} md={4} xs={10}>
                            <Card className="shadow">
                                <div className=" card_custom Card.Body">

                                     <p style={{fontSize: "16px",color: "black" }}><b>Balance Sheet</b>
                                     <FontAwesomeIcon icon={faQuestionCircle} style={{marginLeft:"8rem",color:"gray"}}></FontAwesomeIcon></p>
                                     <p style={{fontSize: "10px",color: "grey",marginTop: "3rem",marginBottom: "0"}}>
                                    Last Updated 17 Jan 2020,11:24 (GMT)</p>
                                </div>
                            </Card>
                        
                        </Col>
                     <Col lg={4} md={4} xs={10}>
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                <p style={{fontSize: "16px",color: "black"}}><b>Balance Sheet</b>
                                <FontAwesomeIcon icon={faQuestionCircle} style={{marginLeft:"8rem",color:"gray"}}></FontAwesomeIcon></p>
                                <p style={{fontSize: "10px",color: "grey",marginTop: "3rem",marginBottom: "0"}}>
                                Last Updated 17 Jan 2020,11:2 (GMT)</p>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={4} md={4} xs={10}>
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                   <p style={{fontSize: "16px",color: "black"}}><b>Trial Balance </b>
                                   <FontAwesomeIcon icon={faQuestionCircle} style={{marginLeft:"8rem",color:"gray"}}></FontAwesomeIcon></p>
                                   <p style={{fontSize: "10px",color: "grey",marginTop: "3rem",marginBottom: "0"}}>
                                    Last updated 12 Jan 2020,
                                   11:2 (GMT)</p>
                                </div>
                            </Card>
                        </Col>
                        </Row>
                        <Row className="mt-3">
                        <Col  lg={4} md={4} xs={10}>
                            <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                <p style={{fontSize: "16px",color: "black"}}><b>Owners Report </b>
                                <FontAwesomeIcon icon={faQuestionCircle} style={{marginLeft:"8rem",color:"gray"}}></FontAwesomeIcon></p>
                                   <p style={{fontSize: "10px",color: "grey",marginTop: "3rem",marginBottom: "0"}}>
                                    Last updated 12 Jan 2020,
                                   11:2 (GMT)</p>
   
                                </div>
                            </Card>
                        </Col>
                        <Col lg={4} md={4} xs={10}>
                        <Card className=" shadow">
                                <div className=" card_custom Card.Body">
                                <p style={{fontSize: "16px",color: "black"}}><b>Others </b>
                                <FontAwesomeIcon icon={faQuestionCircle} style={{marginLeft:"11rem",color:"gray"}}></FontAwesomeIcon></p>
                                   <p style={{fontSize: "10px",color: "grey",marginTop: "3rem",marginBottom: "0"}}>
                                    Last updated 12 Jan 2020,
                                   11:2 (GMT)</p>

                                </div>
                            </Card>
                        </Col>
                     
                </Row>


            </>

        );
    }
}