import React, { Component } from "react";
//import Profile from "./images/profile.png";
// Table from react-bootstrap
import { Table} from "react-bootstrap";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// To make rows collapsible
import "bootstrap/js/src/collapse.js";

export class Opexdatatable extends Component {
    render() {
        return (
            <>
                <Table  responsive bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Account</th>
                            <th>Item</th>
                            <th>This Period</th>
                            <th>Budget per Month</th>
                            <th>Variance Month</th>
                            <th>Actual YTD</th>
                            <th>Budget YTD</th>
                            <th>Variance YTD</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            data-toggle="collapse"
                            data-target=".multi-collapse1"
                            aria-controls="multiCollapseExample1"
                        >
                            <td>1</td>
                            <td>500100000</td>
                            <td><b>Manning</b></td>
                            <td>74,762.12</td>
                            <td>76,116.00</td>
                            <td>1,369.98</td>
                            <td>209,354.97</td>
                            <td>228,348.00</td>
                            <td>18.993.03</td>
                        </tr>
                        <tr class="collapse multi-collapse1" id="multiCollapseExample2"
                        data-toggle="collapse"
                        data-target=".multi-collapse2"
                        aria-controls="multiCollapseExample2q">
                            <td>1.2</td>
                            <td>500100000</td>
                            <td>Wages</td>
                            <td>76,304,32</td>
                            <td>67,550.00</td>
                            <td><span style={{color:"red"}}>(2,754.32)</span></td>
                            <td>1,97,021,69</td>
                            <td>202,650.00</td>
                            <td>5,5625.31</td>
                        </tr>
                        <tr class="collapse multi-collapse2" id="multiCollapseExample2">
                            <td>1.1.1</td>
                            <td>500101000</td>
                            <td><span style={{color:"#03e8fc"}}>Mann-WG-Officer</span></td>
                            <td>70,304.32</td>
                            <td>67,550.00</td>
                            <td><span style={{color:"red"}}>(2,754.32)</span></td>
                            <td>197.021.69</td>
                            <td>202,650.00</td>
                            <td>5,528.32</td>
                        </tr>
                        <tr class="collapse multi-collapse1" id="multiCollapseExample1"
                        data-toggle="collapse"
                        data-target=".multi-collapse3"
                        aria-controls="multiCollapseExample3">
                            <td>1.2</td>
                            <td>500102000</td>
                            <td>Overtime</td>
                            <td>12,461.80</td>
                            <td>2,158.00</td>
                            <td><span style={{color:"red"}}>(208.80)</span></td>
                            <td>6,393.28</td>
                            <td>6,474.00</td>
                            <td>80.72</td>
                        </tr>
                        <tr class="collapse multi-collapse3" id="multiCollapseExample3">
                            <td>1.2.1</td>
                            <td>500102001</td>
                            <td><span style={{color:"#03e8fc"}}>Mann-OT-Overtime-Deck</span></td>
                            <td>2,461.80</td>
                            <td>2,158.00</td>
                            <td><span style={{color:"red"}}>(303.80)</span></td>
                            <td>6,393.28</td>
                            <td>6,474.00</td>
                            <td>80.72</td>
                        </tr>
                        <tr class="collapse multi-collapse1" id="multiCollapseExample2"
                        data-toggle="collapse"
                        data-target=".multi-collapse4"
                        aria-controls="multiCollapseExample4">
                            <td>1.3</td>
                            <td>500103000</td>
                            <td>Crew Travel</td>
                            <td>1,980.00</td>
                            <td>6,408.00</td>
                            <td>4,426.00</td>
                            <td>5,940.00</td>
                            <td>19,224.90</td>
                            <td>13,284.00</td>
                        </tr>
                        <tr class="collapse multi-collapse4" id="multiCollapseExample4">
                            <td>1.3.1</td>
                            <td>500103001</td>
                            <td><span style={{color:"#03e8fc"}}>Mann-OT-Overtime-Deck</span></td>
                            <td>990.00</td>
                            <td>3,204.00</td>
                            <td>2,214.00</td>
                            <td>2,970.00</td>
                            <td>9,612.00</td>
                            <td>6,642.00</td>
                        </tr>
                        <tr class="collapse multi-collapse4" id="multiCollapseExample4">
                            <td>1.3.2</td>
                            <td>500103002</td>
                            <td><span style={{color:"#03e8fc"}}>Mann-OT-Overtime-Deck</span></td>
                            <td>990.00</td>
                            <td>3,204.00</td>
                            <td>2,214.00</td>
                            <td>2,970.00</td>
                            <td>9,612.00</td>
                            <td>6,642.00</td>
                        </tr>
                        <tr
                            data-toggle="collapse"
                            data-target=".multi-collapse2"
                            aria-controls="multiCollapseExample2"
                        >
                           <td>2</td>
                            <td>500400000</td>
                            <td><b>Lubricating Oils</b></td>
                            <td>6,689.06</td>
                            <td>5,779.00</td>
                            <td><span style={{color:"red"}}>(910.06)</span></td>
                            <td>16,215.36</td>
                            <td>17,337.00</td>
                            <td>1,121.64</td>
                        </tr>
                        <tr class="collapse multi-collapse2" id="multiCollapseExample2">
                            <td>Child col 1</td>
                            <td>Child col 2</td>
                            <td>Child col 3</td>
                        </tr>
                        <tr
                            data-toggle="collapse"
                            data-target=".multi-collapse2"
                            aria-controls="multiCollapseExample2"
                        >
                           <td>3</td>
                            <td>500700000</td>
                            <td><b>Operational Expenses</b></td>
                            <td>22,825.13</td>
                            <td>9,500.00</td>
                            <td><span style={{color:"#03e8fc"}}>(13,325.33)</span></td>
                            <td>31,975.60</td>
                            <td>28,500.00</td>
                            <td><span style={{color:"red"}}>(3,475.69)</span></td>
                        </tr>
                        <tr class="collapse multi-collapse2" id="multiCollapseExample2">
                            <td>Child col 1</td>
                            <td>Child col 2</td>
                            <td>Child col 3</td>
                        </tr>
                       
                    </tbody>
                </Table>
            </>
        );
    }
}

export default Opexdatatable;
